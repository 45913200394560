export const SimpleIcons20x20 = {
  servers: 'M6.5 5.5q-.417 0-.708.292-.292.291-.292.708t.292.708q.291.292.708.292t.708-.292Q7.5 6.917 7.5 6.5t-.292-.708Q6.917 5.5 6.5 5.5Zm0 8q-.417 0-.708.292-.292.291-.292.708t.292.708q.291.292.708.292t.708-.292q.292-.291.292-.708t-.292-.708Q6.917 13.5 6.5 13.5ZM4 3h12q.417 0 .708.292Q17 3.583 17 4v5q0 .417-.292.708Q16.417 10 16 10H4q-.417 0-.708-.292Q3 9.417 3 9V4q0-.417.292-.708Q3.583 3 4 3Zm0 8h12q.417 0 .708.292.292.291.292.708v5q0 .417-.292.708Q16.417 18 16 18H4q-.417 0-.708-.292Q3 17.417 3 17v-5q0-.417.292-.708Q3.583 11 4 11Z',
  download: 'm9.125 8.396-.667-.667q-.25-.25-.604-.25t-.604.25q-.271.271-.271.625t.271.604l2.125 2.146q.271.271.625.271t.625-.271l2.146-2.146q.271-.27.26-.625-.01-.354-.26-.604t-.604-.25q-.355 0-.605.25l-.687.688V5.833q0-.354-.26-.604-.261-.25-.615-.25t-.615.261q-.26.26-.26.614Zm-2.417 5.771h6.604q.355 0 .605-.261.25-.26.25-.614t-.261-.615q-.26-.26-.614-.26H6.688q-.355 0-.605.26-.25.261-.25.615t.261.614q.26.261.614.261ZM10 18.333q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656Z',
  host: 'M10 14.167q.354 0 .615-.261.26-.26.26-.614v-2.417h2.437q.355 0 .605-.25t.25-.625q0-.354-.261-.615-.26-.26-.614-.26h-2.417V6.688q0-.355-.25-.605T10 5.833q-.354 0-.615.261-.26.26-.26.614v2.417H6.688q-.355 0-.605.25t-.25.625q0 .354.261.615.26.26.614.26h2.417v2.437q0 .355.25.605t.625.25Zm0 4.166q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656Z',
  about: 'M10 14.167q.354 0 .615-.261.26-.26.26-.614v-3.271q0-.354-.26-.604-.261-.25-.615-.25t-.615.26q-.26.261-.26.615v3.27q0 .355.26.605.261.25.615.25Zm0-6.688q.354 0 .615-.26.26-.261.26-.615t-.26-.614q-.261-.261-.615-.261t-.615.261q-.26.26-.26.614t.26.615q.261.26.615.26Zm0 10.854q-1.729 0-3.25-.656t-2.646-1.781q-1.125-1.125-1.781-2.646-.656-1.521-.656-3.25t.656-3.25q.656-1.521 1.781-2.646T6.75 2.323q1.521-.656 3.25-.656t3.25.656q1.521.656 2.646 1.781t1.781 2.646q.656 1.521.656 3.25t-.656 3.25q-.656 1.521-1.781 2.646t-2.646 1.781q-1.521.656-3.25.656Z',
  settings: 'M11.583 18.333H8.417q-.334 0-.573-.218-.24-.219-.282-.553l-.229-1.854q-.208-.125-.458-.27-.25-.146-.458-.271l-1.729.729q-.313.125-.626.021-.312-.105-.479-.396l-1.562-2.729q-.167-.292-.094-.604.073-.313.323-.521l1.458-1.125V9.458L2.25 8.333q-.25-.208-.323-.521-.073-.312.094-.604l1.562-2.729q.167-.291.479-.396.313-.104.626.021l1.729.729q.208-.125.458-.27.25-.146.458-.271l.229-1.854q.042-.334.282-.553.239-.218.573-.218h3.166q.334 0 .573.218.24.219.282.553l.229 1.854q.208.125.458.271.25.145.458.27l1.729-.729q.313-.125.626-.021.312.105.479.396L18 7.208q.167.292.104.604-.062.313-.333.521l-1.459 1.125v1.084l1.459 1.125q.271.208.333.521.063.312-.104.604l-1.583 2.729q-.167.291-.479.396-.313.104-.626-.021l-1.729-.729q-.208.125-.458.271-.25.145-.458.27l-.229 1.854q-.042.334-.282.553-.239.218-.573.218ZM10 12.979q1.229 0 2.104-.875T12.979 10q0-1.229-.875-2.104T10 7.021q-1.229 0-2.104.875T7.021 10q0 1.229.875 2.104t2.104.875Z'
}

export const SimpleIcons24x24 = {
  servers: 'M7.5 6q-.625 0-1.062.438Q6 6.875 6 7.5t.438 1.062Q6.875 9 7.5 9t1.062-.438Q9 8.125 9 7.5t-.438-1.062Q8.125 6 7.5 6Zm0 10q-.625 0-1.062.438Q6 16.875 6 17.5t.438 1.062Q6.875 19 7.5 19t1.062-.438Q9 18.125 9 17.5t-.438-1.062Q8.125 16 7.5 16ZM4 3h16q.425 0 .712.287Q21 3.575 21 4v7q0 .425-.288.712Q20.425 12 20 12H4q-.425 0-.712-.288Q3 11.425 3 11V4q0-.425.288-.713Q3.575 3 4 3Zm0 10h16q.425 0 .712.287.288.288.288.713v7q0 .425-.288.712Q20.425 22 20 22H4q-.425 0-.712-.288Q3 21.425 3 21v-7q0-.425.288-.713Q3.575 13 4 13Z',
  download: 'm11 10.15-.9-.875Q9.8 9 9.387 9.012q-.412.013-.687.288-.275.275-.275.7 0 .425.275.7l2.6 2.6q.275.275.7.275.425 0 .7-.275l2.625-2.625q.275-.275.263-.688-.013-.412-.288-.687-.275-.275-.7-.288-.425-.012-.7.288l-.9.85V6.975q0-.425-.287-.7Q12.425 6 12 6t-.712.287Q11 6.575 11 7ZM8 17h8.025q.425 0 .7-.288Q17 16.425 17 16t-.288-.713Q16.425 15 16 15H7.975q-.425 0-.7.287Q7 15.575 7 16t.287.712Q7.575 17 8 17Zm4 5q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Z',
  host: 'M12 17q.425 0 .713-.288Q13 16.425 13 16v-3h3q.425 0 .712-.288Q17 12.425 17 12t-.288-.713Q16.425 11 16 11h-3V8q0-.425-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8v3H8q-.425 0-.713.287Q7 11.575 7 12t.287.712Q7.575 13 8 13h3v3q0 .425.288.712.287.288.712.288Zm-7 4q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h14q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Z',
  about: 'M12 17q.425 0 .713-.288Q13 16.425 13 16v-4.025q0-.425-.287-.7Q12.425 11 12 11t-.712.287Q11 11.575 11 12v4.025q0 .425.288.7.287.275.712.275Zm0-8q.425 0 .713-.288Q13 8.425 13 8t-.287-.713Q12.425 7 12 7t-.712.287Q11 7.575 11 8t.288.712Q11.575 9 12 9Zm0 13q-2.075 0-3.9-.788-1.825-.787-3.175-2.137-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175 1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138 1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175-1.35 1.35-3.175 2.137Q14.075 22 12 22Z',
  settings: 'M13.875 22h-3.75q-.375 0-.65-.25t-.325-.625l-.3-2.325q-.325-.125-.612-.3-.288-.175-.563-.375l-2.175.9q-.35.125-.7.025t-.55-.425L2.4 15.4q-.2-.325-.125-.7.075-.375.375-.6l1.875-1.425Q4.5 12.5 4.5 12.337v-.675q0-.162.025-.337L2.65 9.9q-.3-.225-.375-.6-.075-.375.125-.7l1.85-3.225q.175-.35.537-.438.363-.087.713.038l2.175.9q.275-.2.575-.375.3-.175.6-.3l.3-2.325q.05-.375.325-.625t.65-.25h3.75q.375 0 .65.25t.325.625l.3 2.325q.325.125.613.3.287.175.562.375l2.175-.9q.35-.125.7-.025t.55.425L21.6 8.6q.2.325.125.7-.075.375-.375.6l-1.875 1.425q.025.175.025.337v.675q0 .163-.05.338l1.875 1.425q.3.225.375.6.075.375-.125.7l-1.85 3.2q-.2.325-.562.438-.363.112-.713-.013l-2.125-.9q-.275.2-.575.375-.3.175-.6.3l-.3 2.325q-.05.375-.325.625t-.65.25Zm-1.825-6.5q1.45 0 2.475-1.025Q15.55 13.45 15.55 12q0-1.45-1.025-2.475Q13.5 8.5 12.05 8.5q-1.475 0-2.488 1.025Q8.55 10.55 8.55 12q0 1.45 1.012 2.475Q10.575 15.5 12.05 15.5Z'
}

